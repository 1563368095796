<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				接口账户详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="ApiAccount.Id != 'add'">
					<span class="Sel">接口账户ID</span>
					<em class="Sel">
						{{ApiAccount.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*接口账户描述</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.Description" style="width: 400px;"></el-input>
					</em>
					<span>内部识别用</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">*接口平台</span>
					<em  class="Sel">
						<el-select v-model="ApiAccount.ThirdPlatform">
							<el-option :label="'阿里云通用配置版'" :value="'aliyun'"></el-option>
							<el-option :label="'微信公众号'" :value="'gzh.wx'"></el-option>
							<el-option :label="'微信小程序'" :value="'xcx.wx'"></el-option>
							<el-option :label="'微信支付'" :value="'pay.wx'"></el-option>
							<el-option :label="'微信开放平台'" :value="'open.wx'"></el-option>
							<el-option :label="'支付宝开放平台'" :value="'alipay.open'"></el-option>
							<el-option :label="'极光推送'" :value="'jiguang.push'"></el-option>
							<el-option :label="'快递100'" :value="'kd100'"></el-option>
							<el-option :label="'其它'" :value="'others'"></el-option>
						</el-select>

					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">账号</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.ThirdAppAccount" style="width: 400px;"></el-input>
					</em>
					<span>一般是登录用的账号</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">子账号</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.ThirdAppSubAccount" style="width: 400px;"></el-input>
					</em>
					<span>部分平台存在子账户逻辑，例如苏宁</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">应用ID</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.ThirdAppId" style="width: 400px;"></el-input>
					</em>
					<span>部分平台可能用AppKey替代</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">接口Key</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.ThirdAppKey" style="width: 400px;"></el-input>
					</em>
					<span>部分平台可能用AppId替代</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">*接口秘钥</span>
					<em  class="Sel">
						<el-input v-model="ApiAccount.ThirdAppSecret" style="width: 400px;"></el-input>
					</em>
					<span>如在第三方刷新，请及时修改此处</span>
				</li>

				<li class="Sel" v-if="ApiAccount.Id != 'add'">
					<span class="Sel">状态</span>
					<em class="Sel">
						<el-select placeholder="设置状态" v-model="ApiAccount.Status">
							<el-option label="使用中" :value="50"></el-option>
							<el-option label="已停用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="ApiAccount.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{ApiAccount.CreatedAt}}
					</em>
				</li>
				
				<li v-if="ApiAccount.Id != 'add'" class="Sel">
					<span class="Sel">最后修改</span>
					<em class="Sel">
						{{ApiAccount.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button @click="Update()" type="warning">{{ApiAccount.Id == 'add' ? '添加':'修改'}}</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber} from 'element-ui'
	export default {
	  name: 'ApiAccountInfo',
	  props: {
	  },
	  data() {
	      return {
			  ApiAccount:{
				  Id:'',
				  Description:'',
				  ThirdPlatform:'',
				  ThirdAppAccount:'',
				  ThirdAppSubAccount:'',
				  ThirdAppId:'',
				  ThirdAppKey:'',
				  ThirdAppSecret:'',
				  CreatedAt:'',
				  UpdatedAt:'',
				  Status:50,
			  },
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-upload':Upload,
		'el-input-number':InputNumber,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少ID，页面无法工作')
			return
		}
		this.ApiAccount.Id = this.$route.params.Id
		if(this.ApiAccount.Id != 'add'){
			this.GetApiAccount(this.$route.params.Id)
			return
		}
	  },
	  methods:{
		    GetApiAccount(_id){
		  		let data = {Service:'ApiAccount',Class: 'ApiAccount',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.ApiAccount = res.Data
		  		})
		    }, 
			Update(){
				let data = {
					Service:'ApiAccount',
					Class: 'ApiAccount',
					Action: 'Update',
					Id:this.ApiAccount.Id,
					Description:this.ApiAccount.Description,
					ThirdPlatform:this.ApiAccount.ThirdPlatform,
					ThirdAppAccount:this.ApiAccount.ThirdAppAccount,
					ThirdAppSubAccount:this.ApiAccount.ThirdAppSubAccount,
					ThirdAppId:this.ApiAccount.ThirdAppId,
					ThirdAppKey:this.ApiAccount.ThirdAppKey,
					ThirdAppSecret:this.ApiAccount.ThirdAppSecret,
					Status:this.ApiAccount.Status,
				}
				if(this.ApiAccount.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0 && this.ApiAccount.Id == 'add'){
						this.$Jump('/do/api_account/list')
					}else if(res.ErrorId == 0){
						this.GetApiAccount(this.ApiAccount.Id)
					}
				})
			},
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 120px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
</style>
